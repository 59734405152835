<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="container-fluid height-100">
      <div class="row height-100">
        <div class="col-md-6 padd-hori3 border bor" style="padding-top:0px !important;">
          <div class="loginLeftBOX">
            <div slot="brand" class="img-logo">
                <router-link to="/" class="logo-img">
                  <img :src="`/img/logos/${$currentPlatform}_logo.png`" class="logo-img" />
                </router-link>
           </div>

            <div class="my-form">
              <!-- sign up buttons -->
              <div class="container">
                <div class="row d-flex">
                  <div class="col border text-center border-dark rounded leftSignUp_box" style="position: relative; padding-bottom:15px;">
                    <div class="icon border rounded-circle" style=" position: absolute; left: 0px; right: 0; top: -50px; width: 100px; height: 100px; background-color: #530b75; display: flex; align-items: center; justify-content: center; margin: auto;">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>  
                    <router-link to="/user_register" class="text-light"><h2 class="my-5">Individual <br> Sign Up</h2></router-link>

                    <div class="rightArrowBOx">
                      <span></span>
                      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div class="col text-center border mx-2 border-dark rounded leftSignUp_box" style="position: relative; padding-bottom:15px;">
                    <div class="icon border rounded-circle" style=" position: absolute; left: 0px; right: 0; top: -50px; width: 100px; height: 100px; background-color: #530b75; display: flex; align-items: center; justify-content: center; margin: auto;">
                      <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                    <router-link to="/register" class="text-light"><h2 class="my-5">Company <br> Sign Up</h2></router-link>

                    <div class="rightArrowBOx">
                      <span></span>
                      <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- sign up buttons -->
            </div>
          </div>
        </div>
        <div class="col-md-6 px-0 col-12-sm d-none-mobile full-screen padd-hori3" style="padding:0px !important;">
            <div class="loginLeftBOX rightPanelLoginBox px-2">
                <div class="d-flex justify-content-end">

                </div>
                <div class="my-form rightPanelLoginBox_Form">
                  <div class="before-left">
                    <h3>
                        Our commitment to our clients extends beyond typical insurance products.
                    </h3>
                    <p>
                        Origin’s Educational Program provides hospitality specific courses that address special risks and needs of the restaurant, bar and tavern industry.
                    </p>
                  </div>
                    <div class="d-flex ">
                        <!-- <router-link to="/signup" style="background-color:white;" class="btn btn-small">SIGN UP</router-link> -->
                        <router-link
                            to="/login"
                            style="background-color: white"
                            class="btn btn-small"
                        >LOGIN</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";

import "sweetalert2/src/sweetalert2.scss";
import { Dynamic } from "../../wl";
export default {
  name: "register",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      baseUrl: this.$baseUrl,
      complete: false,
      hot_user: "",
      hot_token: "",
      config: "",
      infoEmail: "",
    };
  },
  mounted() {
    this.infoEmail = Dynamic.INFO_EMAIL;
  },
  created() {},

  methods: {},
};
</script>
<style scoped>
/* .h_for_bannner{
  height: 150%;
  padding-bottom: 0;
  margin-bottom: 0;
} */
.logo {
  width: 36%;
}
.back-blur a {
  background-color: #ffb097;
  padding: 12px 36px;
  font-size: 18px;
  color: #000;
  border-radius: 15px;
  font-weight: 500;
  display: inline-block;
  text-transform: uppercase;
}
.back-blur a:hover {
  text-decoration: none;
}
.back-blur p {
  font-size: 26px;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin: 0px;
}
.back-blur h2 {
  font-size: 45px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.back-blur {
  padding: 52px 100px 45px 22px;
  background: linear-gradient(249deg, #ffffffb8, #ffffffad);
  backdrop-filter: blur(3px);
}
.absolute-sec {
  position: absolute;
  top: 61%;
  transform: translateY(-50%);
}
.relative-sec {
  position: relative;
}
.bg-right {
  background-image: url("/assets/images/financial-graph.png");
}
a.signup {
  color: #0767d4;
  font-size: 22px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}
div.no-account {
  font-size: 18px;
}
.social-icons p {
  font-size: 21px;
  font-weight: 500;
}
.social-ul {
  list-style: none;
}
.social-ul li i {
  color: #021a4d;
  font-size: 26px;
}
.social-ul li:nth-child(1) {
  padding: 11px 17px 5px 17px;
  border: 1px solid #c9c9c9;
  border-radius: 46px;
  margin-right: 12px;
}
.social-ul li:nth-child(2) {
  padding: 13px 13px 5px 13px;
  border: 1px solid #c9c9c9;
  border-radius: 46px;
  margin-right: 12px;
}
.social-ul li:nth-child(3) {
  padding: 11px 7px 4px 10px;
  border: 1px solid #c9c9c9;
  border-radius: 46px;
  margin-right: 12px;
}
.my-form h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px !important;
  font-weight: 700;
  color: #324351;
  padding-bottom: 15px;
  margin: 0px;
}
.my-form p {
  font-size: 17px;
  font-weight: 300;
  color: #6a6a6a;
  font-weight: 500;
}
.my-form {
  max-width: 610px;
  margin: auto;
  width: 100%;
}
body {
  background-color: white !important;
  box-sizing: border-box;
  font-size: 16px;
  color: #000;
  font-family: "Montserrat", sans-serif;

  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.height-100 {
  height: 100%;
}
* {
  box-sizing: border-box;
  font-size: 16px;
  color: #000;
  font-family: "Montserrat", sans-serif;
}

.container h1 {
  text-align: center;
  padding: 0 0 20px 0;
  border-bottom: 1px solid silver;
}
.container form {
  padding: 0 40px;
  box-sizing: border-box;
}
form .txt_field {
  position: relative;
}
.txt_field input {
  width: 100%;
  padding: 0px 5px 0px 35px;
  height: 70px;
  font-size: 16px;
  border: 1px solid #eeeeee;
  background: none;
  outline: none;
  border-radius: 0px;
  background-color: #ffffff00;
  padding-left: 70px;
  border-radius: 10px;
}
.relative-item {
  position: relative;
}

.relative-item .absolute-mail {
  position: absolute;
  left: 29px;
  top: 22px;
}
.relative-item .absolute-mail i,
.absolute-eye i {
  color: #3c3c3c;
}

.absolute-eye {
  position: absolute;
  right: 12px;
  top: 22px;
}

.txt_field input:focus ~ label,
.txt_field input:valid ~ label {
  top: 16px;
  color: #000000;
}
.txt_field input:focus ~ span::before,
.txt_field input:valid ~ span::after {
  width: 100%;
}
.pass:hover {
  text-decoration: underline;
}
input[type="submit"] {
  width: 100%;
  height: 50px;
  border: 1px solid black;
  background: linear-gradient(120deg, #f00233, #00ff00);
  border-radius: 25px;
  font-size: 16px;
  color: antiquewhite;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}
input[type="submit"]:hover {
  border-color: #f00233;
  transition: 1s;
  transform: translateY(-3px);
}
.signup_link {
  margin: 30px 0;
  text-align: center;
  font-size: 16px;
  color: #666666;
}
.signup_link a {
  color: rgb(78, 136, 20);
  text-decoration: none;
}
.signup_link a:hover {
  text-decoration: underline;
}
.txt_field input:focus.txt_field {
  background-color: #fff;
}
.txt_field .relative {
  position: absolute;
  top: 60%;
  right: 24px;
  transform: translateY(-50%);
}
.txt_field .relative2 {
  position: absolute;
  top: 73px;
  right: 24px;
  transform: translateY(-50%);
}
.txt_field .relative i {
  font-size: 24px;
  color: #7d7d7d;
}
.txt_field .relative2 i {
  font-size: 26px;
  color: #7d7d7d;
}

.txt_field .relative2 i {
  font-size: 26px;
  color: #7d7d7d;
}

.eye {
  position: absolute;
  top: 56%;
  right: 24px;
  transform: translateY(-50%);
}
.txt_field .eye i {
  font-size: 20px;
}
.btn-my {
  font-size: 22px;
  width: 100%;
  color: #ffffff;
  padding: 15px 0px;
  border-radius: 0px;
  font-family: "Poppins";
  border: 0px solid #1a421e;
  font-weight: 600;
  font-family: "Montserrat";
  background-color: #03aba7;
  box-shadow: inset 1px 1px 13px #47474780;
  border-radius: 47px;
}
.pass {
  margin: -5px 0 9px 0;
  color: #000000;
  cursor: pointer;
  padding-top: 15px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
}
.padd-hori {
  min-height: 77vh;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 2px !important;
}
.padd-hori2 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  padding: 50px 2px !important;
}
.padd-hori3 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 2px !important;
  position: relative;
}
.padd-hori3:after {
  /* content: "";
    */
  width: 1px;
  position: absolute;
  height: 70vh;
  right: 0px;
  background-color: #0f75c9;
}
.padd-hori4 {
  min-height: 77vh;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 2px !important;
  background-color: #fff;
}
.background-main {
  background: url("/assets/images/login-bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.main-wrapper {
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.wrapper {
  width: 100%;
  max-width: 70%;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
}
.txt_field label {
  font-size: 24px;
  color: #7d7d7d;
  font-weight: 500;
}
.bottom-text p {
  font-size: 15px;
  font-weight: 400;
  color: #787878;
}
.bottom-text a {
  font-size: 15px;
  font-weight: 400;
  text-decoration: underline;
}
.round {
  position: relative;
}
.round label {
  border: 2px solid #fff;
  border-radius: 0;
  cursor: pointer;
  height: 22px;
  left: 0px;
  position: absolute;
  top: 1px;
  width: 22px;
  margin-bottom: -8px;
}
.round label:after {
  border: 2px solid #424242;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}
.round input[type="checkbox"] {
  visibility: hidden;
  width: 24px;
}
.round input[type="checkbox"]:checked + label {
  background-color: #fff;
  border-color: #e7e7e7;
}
.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.round span {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.absolute-left {
  position: absolute;
  bottom: 40px;
  left: -55px;
}
.absolute-right {
  position: absolute;
  top: 40px;
  left: 50px;
}
.logo-head {
  margin-bottom: 10px;
}
.logo-head img {
  width: 100%;
  max-width: 150px;

  display: block;
  text-align: center;
}
.truck-lg img {
  width: 100%;
}

.dropdown-menu2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.txt_field label {
  font-size: 19px;
  color: #303030;
  font-weight: 500;
}

.bg-grey {
  background-color: #f7f7f7;
}

.txt_field input::placeholder {
  color: #303030;
  font-size: 16px;
  font-weight: 500;
}
.d-register {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.d-register p {
  font-size: 16px;
  color: #565656;
}
.d-register p a {
  color: #296031;
}

.heading-text {
  margin-top: 2.5rem;
  width: 100%;
  max-width: 884px;
  margin-left: auto;
  margin-right: auto;
}
.heading-text h3 {
  font-size: 2.2rem;
  text-align: center;
  font-weight: 700;
  color: #607d8b;
}
.heading-text h3 {
  font-size: 2.2rem;
  text-align: center;
  font-weight: 700;
  color: #607d8b;
}
.truck-lg {
  max-width: 90%;
}

.bg-slider-1 {
  background-image: url("/assets/images/slider-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.bg-slider-2 {
  background-image: url("/assets/images/slider-2.jpg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.full-screen {
  background-image: url("/assets/images/bg-right.png");
  height: 100vh;
  background-size: cover;
}

.full-screen {
  background-image: url("/assets/images/bg-right.png");
  height: 100vh;
  background-size: cover;
  position: relative;
}
.full-screen::before {
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(#00aca82b, #00aca8);
  position: absolute;
  border-top-left-radius: 100px;
}
.main-zindex {
  z-index: 999;
  position: relative;
  height: 100%;
  padding: 3rem 0px 4rem 0px;
}

.button-right button {
  background-color: #fff;
  border: 0;
  height: 56px;
  width: 100%;
  max-width: 195px;
  border-radius: 34px;
  font-size: 20px;
  font-weight: 600;
  color: #6d6d6d;
}
.before-left h3 {
  font-size: 35px;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
  position: relative;
  padding-left: 20px;
}
.before-left p {
  color: #fff;
  font-size: 20px;
}
.before-left h3::before {
  position: absolute;
  background-color: #fff;
  content: "";
  height: 87%;
  width: 5px;
  left: 0;
  top: 11px;
}
.pad-left-cus {
  padding-left: 6rem;
}
.inner-form {
  box-shadow: 2px 2px 11px #e0e0e0;
  padding: 35px 54px;
}
.img-logo {
  margin-bottom: 5rem;
}
.img-logo img.logo-img {
  max-width: 200px;
  width: 100%;
}
.lock-cus {
  top: 16px !important;
}

input.my-input.email:focus {
  border: 1px solid #c4c4c4;
  border-left: 8px solid #00aca8;
}
input.my-input.email:focus,
input.my-input.password:focus {
  border: 1px solid #c4c4c4;
  border-left: 8px solid #00aca8;
}
@media only screen and (max-width: 767px) {
  .d-none-mobile {
    display: none;
  }
  .absolute-left,
  .absolute-right {
    display: none;
  }
  .wrapper {
    width: 100%;
    max-width: 91%;
    background-color: #ffffff;
    border-radius: 30px;
    position: relative;
  }

  .my-form {
    max-width: 80%;
    margin: auto;
    width: 100%;
  }
}



.loginViewport_height {
  height: 100vh;
}

.loginLeftBOX {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.rightPanelLoginBox_Form {
    margin: 0px auto;
    max-width: 700px;
}

.rightPanelLoginBox {
    padding-bottom: 200px;
    justify-content: space-between;
}

.leftSignUp_box a h2[data-v-ba39dcc6] {
    font-size: 22px !important;
    margin: 65px 10px 5px !important;
}

.rounded-circle i {
    color: #fff;
}


.rightArrowBOx {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightArrowBOx  span {
    width: 10px;
    height: 10px;
    background: #530b75;
    border-radius: 50%;
    border: 1px solid #000;
    margin-right: 8px;
}

.rightArrowBOx i {
    font-size: 25px;
    color: #0db0ac;
}
</style>
